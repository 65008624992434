import React, { ReactNode } from 'react';
import cx from 'classnames';
import composePSStyles from '../../../styles/composePSStyles';
import { BaseAlert } from '..';

export type Props = {
    title?: string;
    content: string;
    className?: string;
    children?: ReactNode;
    iconClassName?: string;
    contentClassName?: string;
};

const WarningAlert = (props : Props) => {
    return (
        <div className={cx(props.className, composePSStyles('alert', 'alert-warning', 'd-flex', 'mt-2'))} role="alert">
            <BaseAlert {...props} />
        </div>
    );
};

export default WarningAlert;
