// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles-module__loginContainer___Q_iYl{border:1px solid var(--ps-perx-border)}@media screen and (min-width: 768px){.styles-module__loginContainer___Q_iYl{padding-left:30px;padding-right:30px}}@media screen and (max-width: 768px){.styles-module__loginContainer___Q_iYl{padding-left:15px;padding-right:15px}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loginContainer": "styles-module__loginContainer___Q_iYl"
};
export default ___CSS_LOADER_EXPORT___;
