// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles-module__primaryButton___szrBA{padding-top:16px !important;padding-bottom:16px !important}.styles-module__primaryButton___szrBA:focus{box-shadow:inset 0 0 0 3px rgba(255,255,255,.5) !important}.styles-module__buttonDisabled___pUjIr:hover,.styles-module__buttonDisabled___pUjIr:disabled{border:none;background-color:#000 !important}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"primaryButton": "styles-module__primaryButton___szrBA",
	"buttonDisabled": "styles-module__buttonDisabled___pUjIr"
};
export default ___CSS_LOADER_EXPORT___;
