import React from "react";
import composePSStyles from "../../styles/composePSStyles";
import styles from './styles.module.scss';
import cx from 'classnames';

type LabelProps = {
    text: string
}

const Label = (props : LabelProps) => {
    return (<label className={cx(styles.label,composePSStyles('pt-2', 'pb-1', 'font-weight-bold'))}>{props.text}</label>)
}

export default Label;