// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles-module__verifyButton___kQT4h a,.styles-module__verifyButton___kQT4h a:hover{text-decoration:none !important;background-color:var(--ps-primaryColorCTA);color:#fff}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"verifyButton": "styles-module__verifyButton___kQT4h"
};
export default ___CSS_LOADER_EXPORT___;
