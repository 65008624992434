import React from 'react';
import composePSStyles from '../../styles/composePSStyles';
import cx from 'classnames';

export interface Props {
    className?: string;
}

const Loader = (props : Props) => {
    return (
        <div className={cx(props.className, composePSStyles('fa-1x'))}>
            <i className={composePSStyles('fas', 'fa-spinner', 'fa-spin')}></i>
        </div>
    );
};

export default Loader;
