import React, { BaseSyntheticEvent } from "react";
import { SecondaryButton } from "../../../common/components/Buttons";
import { selectCommunityInfo, selectCommunitySSOEnabled } from "../../../reducers/viewData/selectors";
import { useSelector } from 'react-redux';
import composePSStyles from "../../../common/styles/composePSStyles";

const SSOButton = () => {
    const communityInfo = useSelector(selectCommunityInfo);
    const showSSOLogin = useSelector(selectCommunitySSOEnabled);

    const handleClick = (e:BaseSyntheticEvent) => {
        e.preventDefault();
        var redirectUrl = window.location.pathname.replace("login", "").replace(/\//g, "");
        window.location.href = '/auth/sp-signin?redirectUrl=' + redirectUrl;
    }

    if (!showSSOLogin) {
        return null;
    }

    return (
         <SecondaryButton as="button" className={composePSStyles('mb-2', 'text-wrap')} onClick={handleClick}>{communityInfo.ssoLoginButtonText}</SecondaryButton>
    )
}

export default SSOButton;