import React from 'react';
import cx from 'classnames';
import composePSStyles from '../../../styles/composePSStyles';
import BaseAlert from '../BaseAlert';
import styles from './styles.module.scss';

export type Props = {
    title?: string;
    content: string | JSX.Element;
    contentClassName?: string;
};

const ErrorAlert = (props : Props) => {
    return (
        <div className={cx(styles.content, composePSStyles('alert', 'alert-danger', 'd-flex', 'mt-2'))} role="alert">
            <BaseAlert {...props} />
        </div>
    );
};

export default ErrorAlert;
