import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { WarningAlert } from '../../../common/components/Alerts';
import { SecondaryButton } from '../../../common/components/Buttons';
import HorizontalLine from '../../../common/components/HorizontalLine';
import { validateEmail } from '../../../common/services/PscApi';

import composePSStyles from '../../../common/styles/composePSStyles';
import { selectActiveCommunity } from '../../../reducers/community/selectors';
import { selectOktaWidgetConfig } from '../../../reducers/oktaLogin/selectors';
import SSOButton from '../SSOButton';
import styles from './styles.module.scss';
import { selectCommunitySSOEnabled } from '../../../reducers/viewData/selectors';

type Props = {
    onChangeToPasswordLogin?: () => void;
};

const PasswordLogin = (props: Props) => {
    const oktaWidgetConfig = useSelector(selectOktaWidgetConfig);
    const usernameInputId = 'okta-signin-username';
    const [warningMessage, setWarningMessage] = useState('');
    const activeCommunity = useSelector(selectActiveCommunity);
    const showSSOLogin = useSelector(selectCommunitySSOEnabled);

    const handleInputChange = (e: FocusEvent) => {
        const usernameElement = document.getElementById(usernameInputId);
        const eventTarget = e.target as HTMLInputElement;
        if (usernameElement && eventTarget.id === usernameInputId) {
            if (eventTarget.value?.length > 3) {
                validateEmail(eventTarget.value, activeCommunity?.communityId || 0).then(result => {
                    setWarningMessage(result.data?.resultMessage);
                });
            }
        }
    };

    const showEmailLogin = () => {
        const authParams: string[] = ['passwordlogin', 'completepasswordreset'];
        const searchParams = new URLSearchParams(window.location.search.toLowerCase());

        return !authParams.some(item => searchParams.has(item));
    };

    const loadOkta = (window as any).loadOkta;

    useEffect(() => {
        if (
            typeof loadOkta !== 'undefined' &&
            oktaWidgetConfig.clientId &&
            oktaWidgetConfig.domain &&
            oktaWidgetConfig.forgotPassword &&
            oktaWidgetConfig.issuer &&
            oktaWidgetConfig.redirectUri &&
            oktaWidgetConfig.widgetElementId
        ) {
            loadOkta(oktaWidgetConfig);
        }
        document.addEventListener('focusout', handleInputChange);
        return () => {
            document.removeEventListener('focusout', handleInputChange);
        };
    }, [
        loadOkta,
        oktaWidgetConfig.clientId,
        oktaWidgetConfig.domain,
        oktaWidgetConfig.forgotPassword,
        oktaWidgetConfig.issuer,
        oktaWidgetConfig.redirectUri,
        oktaWidgetConfig.widgetElementId,
    ]);

    return (
        <div className={composePSStyles('col-12')}>
            {warningMessage && <WarningAlert content={warningMessage} className={styles.verifyButton} />}
            <div className={composePSStyles('d-flex', 'justify-content-center', 'flex-column')}>
                <div id="widget-container" className={composePSStyles('py-3')}></div>
            </div>
            {
                (showSSOLogin || showEmailLogin()) &&
                <div className={composePSStyles('row')}>
                    <div className={composePSStyles('col-12', 'd-flex', 'justify-content-center', 'flex-column')}>
                        <HorizontalLine text="OR:" className={composePSStyles('mb-3')} />
                        <SSOButton />
                        {showEmailLogin() &&
                            <SecondaryButton as="button" className={composePSStyles('mb-2')} onClick={props.onChangeToPasswordLogin}>
                                Sign in with an Email Link
                            </SecondaryButton>
                        }
                    </div>
                </div>
            }
        </div>
    );
};

export default PasswordLogin;
