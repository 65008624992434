// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles-module__horizontalLine___qmI4o{width:100%;text-align:center;border-bottom:1px solid var(--ps-perx-border);line-height:.1em;margin:10px 0 10px}.styles-module__horizontalLine___qmI4o span{background:#fff;padding:0 10px;font-weight:bold}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"horizontalLine": "styles-module__horizontalLine___qmI4o"
};
export default ___CSS_LOADER_EXPORT___;
