import React from 'react';
import { createRoot } from 'react-dom/client';
import AppRoot from '../common/AppRoot';
import { elementIds } from '../constants';
import LoginContainer from './components/LoginContainer';

const rootElementId = 'magic-link-login';
const container = document.getElementById(rootElementId);
if (container) {
    const root = createRoot(container);
    root.render(
        <AppRoot>
            <div id={elementIds.loginId}></div>
            <LoginContainer/>
        </AppRoot>,
    );
}