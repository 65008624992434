import DOMPurify from 'dompurify';
import React, { ReactNode } from 'react';
import composePSStyles from '../../styles/composePSStyles';
import cx from 'classnames';
import styles from './styles.module.scss';

export type Props = {
    title?: string;
    content: JSX.Element | string;
    children?: ReactNode;
    classNames?: string;
    contentClassName?: string;
    iconClassName?: string;
};

const BaseAlert = (props : Props) => {
    const hasChildren = props.children && props.children?.toString()?.length > 0;
    const isContentTypeString = typeof props.content === 'string';
    const cleanHTML = DOMPurify.sanitize(props.content as string, {
        USE_PROFILES: { html: true },
    });
    return (
        <>
            {hasChildren && (
                <div
                    className={
                        props.iconClassName ||
                        composePSStyles('col-2', 'px-0', 'd-flex', 'justify-content-center', 'mx-1')
                    }
                >
                    {props.children}
                </div>
            )}
            <div className={props.contentClassName || composePSStyles(hasChildren ? 'col-10' : 'col-12', 'pl-0')}>
                {props.title && (
                    <h3 className={cx(styles.title, composePSStyles('font-weight-bold'))}>{props.title}</h3>
                )}
                {isContentTypeString && (
                    <div className={props.classNames} dangerouslySetInnerHTML={{ __html: cleanHTML }}></div>
                )}
                {!isContentTypeString && <div className={props.classNames}>{props.content}</div>}
            </div>
        </>
    );
};

export default BaseAlert;
