import React, { BaseSyntheticEvent, MutableRefObject, useEffect, useState } from 'react';
import { PrimaryButton, SecondaryButton } from '../../../common/components/Buttons';
import HorizontalLine from '../../../common/components/HorizontalLine';
import BaseInput from '../../../common/components/InputText/BaseInput';
import composePSStyles from '../../../common/styles/composePSStyles';
import SucessAlert from '../../../common/components/Alerts/Success';
import EmailSvg from '../../../common/svg/Email';
import Label from '../../../common/components/Label';
import SSOButton from '../SSOButton';
import { useSelector } from 'react-redux';
import { selectCurrentUser, selectIsSoftAuth, selectViewData } from '../../../reducers/viewData/selectors';
import { sendLoginLinkEmail } from '../../../common/services/PscApi';
import { ErrorAlert, WarningAlert } from '../../../common/components/Alerts';
import { isEmailValid } from '../../../common/helpers';
import cx from 'classnames';
import styles from './styles.module.scss';
import Loader from '../../../common/svg/Loader';

type Props = {
    onChangeToPasswordLogin?: () => void;
};

const EmailLogin = ({ ...props } : Props) => {
    const [emailSent, setEmailSent] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [email, setEmail] = useState('');
    const [emailSentTo, setEmailSentTo] = useState('');
    const [resended, setResended] = useState(false);
    const [loading, setLoading] = useState(false);
    const user = useSelector(selectCurrentUser);
    const isSoftAuth = useSelector(selectIsSoftAuth);
    const [displayMissingEmailWarning, setDisplayMissingEmailWarning] = useState(false);
    const currentCommunity = useSelector(selectViewData)?.currentCommunity;

    const inputRef = React.useRef<HTMLInputElement>();

    useEffect(() => {
        if (isSoftAuth && user?.primaryEmail) {
            setEmailSentTo(user.primaryEmail);
            setEmail(user.primaryEmail);
        }
    }, [emailSentTo]);

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            sendEmail();
        }
    };

    const looseFocus = () => {
        const button = document.getElementById('loginSubmitButton');
        button?.blur();
        inputRef.current?.blur();
    };

    const sendEmail = () => {
        setLoading(true);
        setEmailSent(false);
        setDisplayMissingEmailWarning(false);
        looseFocus();

        if (emailSent) {
            if (resended) {
                setLoading(false);
                return;
            }
            setResended(true);
        }

        if (!checkIfEmailIsValid()) {
            setResended(false);
            setLoading(false);
            return;
        }

        sendLoginLinkEmail(email)
            .then(resp => {
                if (resp.data.resultCode === 'Failure') {
                    setDisplayMissingEmailWarning(true);
                    setEmailSent(false);
                    return;
                }
                setDisplayMissingEmailWarning(false);

                setEmailSent(true);
                setEmailSentTo(email);
            })
            .catch(() => {
                setErrorMessage('Oops, something went wrong. Check to make sure the email you entered is correct.');
                setEmailSent(false);
            })
            .finally(() => setLoading(false));
    };

    const checkIfEmailIsValid = () => {
        const isValid = isEmailValid(email);
        if (isValid) {
            setDisplayMissingEmailWarning(false);
            setErrorMessage('');
        } else {
            setErrorMessage('Please enter a valid email.');
        }
        return isValid;
    };

    const successAlert = (resended: boolean) => {
        return resended ? (
            <SucessAlert
                title="Email Re-sent"
                content={`We’ve attempted to resend your email. If you still have not received an email try checking your spam folder, trying a different email address, or contacting the HR department for your organization.`}
            >
                <EmailSvg />
            </SucessAlert>
        ) : (
            <SucessAlert
                title="Check Your Email"
                content={`Thanks! If you have an active account with us, we’ll send a confirmation email to <b>${emailSentTo}</b> immediately. Use the link in that email to sign in.`}
            >
                <EmailSvg />
            </SucessAlert>
        );
    };

    const missingEmailMessage = () => {
        if (!displayMissingEmailWarning) return null;
        const marketingCommunityId = 252;
        return (currentCommunity?.communityId ?? 0) !== marketingCommunityId &&
            (currentCommunity?.canSignUp ?? false) ? (
            <WarningAlert
                content={`Sorry, it looks like we don't recognize that email or the account has been disabled. Please try a different email address or <a href='/register?communityId=${currentCommunity.communityId}'>create an account</a>`}
                iconClassName={composePSStyles('mr-2')}
                contentClassName={composePSStyles('w-100')}
            >
                <i className="fa fa-exclamation-circle"></i>
            </WarningAlert>
        ) : (
            <ErrorAlert
                contentClassName="px-0"
                content={`Sorry, it looks like we don’t recognize that email or the account is no longer active. Please try a different email address or contact your HR team to create an account.`}
            />
        );
    };

    return (
        <div className={composePSStyles('col-12', 'd-flex', 'justify-content-center', 'flex-column', 'p-3')}>
            {emailSent && successAlert(resended)}

            {errorMessage && <ErrorAlert content={errorMessage} />}

            {missingEmailMessage()}

            <Label text="Email" />

            <BaseInput
                type="email"
                name="username"
                placeholder="example@example.com"
                autoComplete="username"
                defaultValue={emailSentTo}
                onChange={(event: BaseSyntheticEvent) => setEmail(event.target.value)}
                onKeyDown={handleKeyDown}
                ref={inputRef as MutableRefObject<HTMLInputElement>}
            />

            {emailSent ? (
                <PrimaryButton
                    id="loginSubmitButton"
                    as="button"
                    className={cx(
                        styles.primaryButton,
                        (resended || loading) && styles.buttonDisabled,
                        composePSStyles('my-2'),
                    )}
                    onClick={() => sendEmail()}
                    disabled={loading || resended}
                >
                    {loading ? <Loader /> : 'Re-send Email'}
                </PrimaryButton>
            ) : (
                <PrimaryButton
                    id="loginSubmitButton"
                    as="button"
                    className={cx(styles.primaryButton, loading && styles.buttonDisabled, composePSStyles('my-2'))}
                    onClick={() => sendEmail()}
                    disabled={loading}
                >
                    {loading ? <Loader /> : 'Sign In with Email Link'}
                </PrimaryButton>
            )}
            <HorizontalLine text="OR:" className={composePSStyles('my-3')} />
            <SSOButton />
            <SecondaryButton as="button" className={composePSStyles('mb-2')} onClick={props.onChangeToPasswordLogin}>
                Sign in with Password
            </SecondaryButton>
        </div>
    );
};

export default EmailLogin;
