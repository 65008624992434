import React from 'react';
import styles from './styles.module.scss';
import cx from 'classnames';

type Props = {
    text: string;
    className?: string;
};

const HorizontalLine = (props: Props) => (
   <div className={cx(props.className,styles.horizontalLine)}>
        <span>{props.text}</span>
   </div>
);

export default HorizontalLine;
