import DOMPurify from "dompurify";

export const passwordRequirements = {
    regex: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,24}$/,
    message: 'Password is required and must be between 8-24 characters long and contain at least one lowercase letter, one uppercase letter and one number.'
}
export const arePasswordRequirementsMet = (value: string) => passwordRequirements.regex.test(value);

export const sanitizeHtml = function (value: string) {
    const allowedTags = [ 'a', 'em', 'i', 'b', 'strong', 'mark', 'ins', 'del', 'p', 'ul', 'ol', 'li', 'br', 'img',
     'sub', 'sup', 'table', 'tr', 'td', 'th', 'tbody', 'thead', 'tfoot', 'caption', 'div', 'span', 'pre', 'address',
      'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'hr', 'small', 'button', 'svg', 'g' ];
    const allowedAttributes = [ 'class', 'style', 'href', 'src', 'height', 'width', 'title', 'alt' ];

    return DOMPurify.sanitize(value, { ALLOWED_TAGS: allowedTags, ALLOWED_ATTR: allowedAttributes });
};

export const isiOSHandheld = () => !!navigator.platform.match(/iPhone|iPod|iPad/);

const emailReg = new RegExp(
    /^((")(.+?@)|(([0-9a-z_]((\.(?!\.))|[-!#\$%&'\*\+\/=\?\^`\{\}\|~\w])*)(?:[0-9a-z_])@))((\[\]\")*(\[(\d{1,3}\.){3}\d{1,3}\])|(([0-9a-z][-\w]*[0-9a-z]*\.)+[a-z0-9][\-a-z0-9]{0,22}[a-z0-9]))$/,
);
export const isEmailValid = (email: string) => {
    return emailReg.test(email.trim().toLowerCase());
};
