import React, { ReactNode } from "react";
import composePSStyles from "../../../styles/composePSStyles";
import BaseAlert from "../BaseAlert";

export type Props = {
    title?: string;
    content: string;
    children?: ReactNode;
}

const SucessAlert = (props : Props) => {
    return (
        <div className={composePSStyles('alert', 'alert-success', 'd-flex', 'mt-2', 'pl-0')} role="alert">
            <BaseAlert {...props} />
        </div>
    )
}

export default SucessAlert;
