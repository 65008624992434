import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import EmailLogin from '../EmailLogin';
import styles from './styles.module.scss';
import composePSStyles from '../../../common/styles/composePSStyles';
import PasswordLogin from '../PasswordLogin';
import { selectIsEmailLoginPrimary } from '../../../reducers/viewData/selectors';
import { useSelector } from 'react-redux';

const LoginContainer = () => {
    const isEmailLoginPrimary = useSelector(selectIsEmailLoginPrimary);
    const initialState = isEmailLoginPrimary ? 'Email' : 'UsernamePassword';
    const [step, setStep] = useState(initialState);
    useEffect(() => {
        const params: string[] = ['passwordlogin', 'completepasswordreset'];
        const urlSearchParams = new URLSearchParams(window.location.search.toLowerCase());
        const pwLoginRequired = params.some(item => urlSearchParams.has(item));

        if (pwLoginRequired) {
            setStep('UsernamePassword');
        }
    }, [window.location.search]);

    return (
        <div className={cx(styles.loginContainer, composePSStyles('row', 'm-0', 'px-2', 'pb-1'))}>
            {step === 'Email' ? <EmailLogin onChangeToPasswordLogin={() => setStep('UsernamePassword')} /> : null}
            {step === 'UsernamePassword' ? <PasswordLogin onChangeToPasswordLogin={() => setStep('Email')} /> : null}
        </div>
    );
};

export default LoginContainer;
