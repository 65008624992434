import { CommunitySecurityType, userRoles, entityTypes } from '../../enums';
import { ReduxState } from '../rootReducer';

export const selectViewData = (state: ReduxState) => state.viewData;
export const selectIsSoftAuth = (state: ReduxState) => state.viewData.authenticationType === 'Email';
export const selectCommunityInfo = (state: ReduxState) => state.viewData.currentCommunity;
export const selectCurrentUser = (state: ReduxState) => state.viewData.user;
export const selectObfuscatedUserIds = (state: ReduxState) => state.viewData.obfuscatedUserIds;
export const selectUserConsentData = (state: ReduxState) => state.viewData.userConsentData;
export const selectCommunitySSOEnabled = (state: ReduxState) =>
    state.viewData.currentCommunity?.ssoLoginButtonText &&
    state.viewData.currentCommunity?.communitySecurityType === CommunitySecurityType.SSOv3;
export const selectIsCommunityRegistrationEnabled = (state: ReduxState) =>
    state.viewData.currentCommunity?.isCommunityRegistrationEnabled;
export const selectIsCommunityRegistrationAdmin = (state: ReduxState) =>
    state.viewData.user?.userRoles?.find(
        role =>
            role.identityUserRoleId === userRoles.communityRegistrationAdmin &&
            role.entityId === state.viewData.currentCommunity.communityId &&
            role.entityTypeId === entityTypes.community,
    );
export const selectIsCommunityAdmin = (state: ReduxState) =>
    state.viewData.user?.userRoles?.find(
        role =>
            role.identityUserRoleId === userRoles.communityAdmin &&
            role.entityId === state.viewData.currentCommunity.communityId &&
            role.entityTypeId === entityTypes.community,
    );
export const selectIsEmailLoginPrimary = (state: ReduxState) =>
    state.viewData.currentCommunity?.isEmailLoginPrimary;
export const selectIsBrowserXPopupEnabled = (state: ReduxState) =>
    state.viewData.currentCommunity?.isBxPopupVisible;
export const selectBrowserXPopupLabels = (state: ReduxState) =>
    state.viewData.currentCommunity?.browserXPromotionLabels?.popup;
export const selectIsCommunityParticipationDashboardEnabled = (state: ReduxState) =>
    state.viewData.currentCommunity?.isCommunityParticipationDashboardEnabled;
